<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        enable-background="new 0 0 512 512"
        xml:space="preserve"
        :style="starWidth"
    >
        <polygon
            fill="#FBC02D"
            stroke="#FBC02D"
            stroke-width="37.6152"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="  259.216,29.942 330.27,173.919 489.16,197.007 374.185,309.08 401.33,467.31 259.216,392.612 117.104,467.31 144.25,309.08   29.274,197.007 188.165,173.919 "
        ></polygon>
    </svg>
</template>

<script>
export default {
    name: "Star",
    props: {
        width: Number,
        // text: String,
    },
    computed: {
        starWidth() {
            return {
                "--star-width": this.width + "rem",
            };
        },
    },
};
</script>

<style scoped>
svg {
    width: var(--star-width);
    animation: starRotate 3s linear infinite;
}

@keyframes starRotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>
